<template>
  <div class="ibox shops">
    <div class="ibox-title">
      <h2>{{ $t('shop.promoCodes.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col p-xs" v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</div>
      </div>
    
      <b-table 
          outlined striped
          :items="promoCodes"
          :fields="listFields"
          responsive
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>        
        <template v-slot:cell(title)="row">
          {{row.item.title}}
          <span v-if="row.item.subtitle && row.item.subtitle!=''">
            <br/>{{row.item.subtitle}}
          </span>
        </template>  
        <template v-slot:cell(promo)="row">
          <div v-html="getFormattedPromo(row.item)"></div>
        </template>
        <template v-slot:cell(isActivated)="row">
          <i :class="['fa','fa-check',row.item.isActivated ? 'active' : 'disabled']"></i>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.promoCodes.options.update')}}</a>
            /
          </span>
          <span v-if="isRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.promoCodes.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.promoCodes.create-button')}}</button>

      <b-modal size="xl" ref="createItemModal" :title="$t('shop.promoCodes.create.title')" hide-footer lazy>
        <PromoCodeForm v-on:promoCode-created="onItemCreated" />
      </b-modal>

      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.promoCodes.update.title', {promoCodeID: itemToUpdate._id})" hide-footer lazy>
        <PromoCodeForm :promoCode="itemToUpdate" v-on:promoCode-updated="onItemUpdated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.promoCodes.delete-confirmation', {promoCodeID: itemToRemove._id})}}
      </b-modal>

    </div>
  </div>
</template>

<style scoped>

.fa-check.active {
    color:green;
  }
  .fa-check.disabled {
    color:red;
  }
  
</style>



<script lang="ts">
import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import PromoCodeForm from './PromoCodeForm.vue';
import { languagesTypes } from '@fwk-client/store/types';

import { formatDate, formatDay } from '@igotweb-node-api-utils/formatter';

import { useShopAdmin } from '../../../composables/useShopAdmin';
import { usePromoCodeAdmin } from '../../../composables/usePromoCodeAdmin';

export default defineComponent({
  props: {
  },
  components: {
    PromoCodeForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { shops, selectedShop } = useShopAdmin(props, context);
    const { promoCodes, removePromoCode, updateList, isListLoading, isCreateAllowed,
    isUpdateAllowed,
    isRemoveAllowed,
    isMultiSelectAllowed,
    showNumberOfRows }  = usePromoCodeAdmin(props, context);
    updateList();

    

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);

    

    const numberOfRows:Ref<number> = computed(() => {
        return promoCodes.value.length
    })

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});

    const listLoading = computed(() => {
      return isListLoading.value
    })
    const listItemsSelected:Ref<any[]> = ref([]);

    const datesFormatter = () => {
      return (value:any, key:any, item:any) => {
        if(item.startDate && item.endDate) {
          var startDate = formatDay(item.startDate, currentLanguageCode.value, {isUTC:true});
          var endDate = formatDay(item.endDate, currentLanguageCode.value, {isUTC:true});
          return app.$t('shop.promoCodes.dates.range', [startDate, endDate]);
        }
        else if(item.startDate) {
          return app.$t('shop.promoCodes.dates.from', [formatDay(item.startDate, currentLanguageCode.value, {isUTC:true})]) as string;
        }
        else if(item.endDate) {
          return app.$t('shop.promoCodes.dates.until', [formatDay(item.endDate, currentLanguageCode.value, {isUTC:true})]) as string;
        }
        return app.$t('shop.promoCodes.dates.noDate');
      }
    }

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "code",
        label: ""
      },
      {
        key: "emails",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          if(value && value.length > 0) { return value.join(", "); }
          return app.$t('shop.promoCodes.emails.noEmail');;
        }
      },
      {
        key: "dates",
        label: "",
        formatter: datesFormatter()
      },
      {
        key: "promo",
        label: ""
      },
      {
        key: "isActivated",
        label: ""
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('shop.promoCodes.headers.id') as string;
      listFields[2].label = app.$t('shop.promoCodes.headers.code') as string;
      listFields[3].label = app.$t('shop.promoCodes.headers.emails') as string;
      listFields[4].label = app.$t('shop.promoCodes.headers.dates') as string;
      listFields[5].label = app.$t('shop.promoCodes.headers.promo') as string;
      listFields[6].label = app.$t('shop.promoCodes.headers.isActivated') as string;
      listFields[7].label = app.$t('shop.promoCodes.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const getFormattedPromo = (promoCode:any) => {
      if(promoCode.promos && promoCode.promos.length > 1) {
        var content = "<ul>"
        for(var promo of promoCode.promos) {
          content += "<li>" + formatPromo(promo) + "</li>"
        }
        content += "</ul>"
        return content;
      }
      else if(promoCode.promos && promoCode.promos.length == 1) {
        return formatPromo(promoCode.promos[0]);
      }
      return app.$t('shop.promoCodes.scopes.empty');
    }

    const formatPromo = (promo:any) => {
      var scopeLabel = promo.scope.map((scope:string) => {
        return app.$t("shop.promoCodes.scopes.scope."+scope.toUpperCase())
      }).join(", ");
      var valueLabel = app.$t("shop.promoCodes.scopes.value."+promo.type.toUpperCase(), [promo.value]);
      return app.$t('shop.promoCodes.scopes.pattern', [scopeLabel,valueLabel]);
    }

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
      updateList();
    }

    const onItemUpdated = (item:any) => {
      // We update the promo code
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.hide()
      updateList();
    }
    
    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const removeItem = () => {
      removePromoCode(itemToRemove.value._id).then((response:any) => {
        if(response.removed) {
          // We update the list of shops
          updateList();
        }
        // We reset the user to remove
        itemToRemove.value = {};
      });
    }

    return {
      listItemsSelected,
      listLoading,
      showNumberOfRows,
      numberOfRows,
      listItems,
      promoCodes,
      listFields,
      isCreateAllowed,
      isUpdateAllowed,
      isRemoveAllowed,
      isMultiSelectAllowed,
      showCreateItemModal,
      showUpdateModal,
      confirmRemoveItem,
      itemToUpdate,
      itemToRemove,
      onItemCreated,
      onItemUpdated,
      createItemModal,
      updateItemModal,
      removeItemModal,
      removeItem,
      getFormattedPromo
    }
  }
});
</script>