<template>
  <form role="form" @submit="onFormSubmit" name="promo-code-form" class="promo-code-form">
    <TextField v-bind:value.sync="promoCodeForm.code" id="code" :label="$t('shop.info.promoCode.code')" :placeholder="$t('shop.info.promoCode.code_placeholder')" required labelAsColumn />
    <LocalizedText 
          ref="note"
          v-bind:value.sync="promoCodeForm.note" 
          id="updateNote" 
          :label="$t('shop.info.promoCode.note')" 
          :placeholder="$t('shop.info.promoCode.note_placeholder')" 
          :labelAsColumn="true"
          :required="false"
          :languageCodes="availableLanguageCodes"
          :rowClass="{'form-group':true, 'row':true, 'update-note':true}"
      />
    <ListEMails v-bind:emails.sync="promoCodeForm.emails" :label="$t('shop.info.promoCode.emails')" :placeholder="$t('shop.info.promoCode.emails_placeholder')" />
    <DateInput v-bind:value.sync="promoCodeForm.startDate" :label="$t('shop.info.promoCode.startDate')" labelAsColumn id="startDate" />
    <DateInput v-bind:value.sync="promoCodeForm.endDate" :label="$t('shop.info.promoCode.endDate')" labelAsColumn id="endDate" />
    <div class="form-group row">
      <label for="updateIsActivated" class="col-lg-3 col-form-label">{{$t('shop.info.promoCode.isActivated')}}</label>
      <div class="col-lg-9">
        <p-check id="updateIsActivated" class="p-switch p-fill" color="success" v-model="promoCodeForm.isActivated">&nbsp;</p-check>
      </div>
    </div>
    <div class="form-group row">
      <div class="col">
        <PromoCodeScopes v-bind:promoCode.sync="promoCodeForm"></PromoCodeScopes>
      </div>
    </div>
    <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ buttonLabel }}</button>
  </form>
</template>

<style>
  
</style>

<script lang="ts">
import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import DateInput from '@root/src/client/components/panels/input/DateInput.vue';
import ListEMails from '@root/src/client/components/panels/input/ListEMails.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import PromoCodeScopes from './PromoCodeScopes.vue';

import { useShopAdmin } from '../../../composables/useShopAdmin';
import { usePromoCodeAdmin } from '../../../composables/usePromoCodeAdmin';

export default defineComponent({
  props: {
    promoCode: {
      type: Object as PropType<any>,
      required: false
    }
  },
  components: {
    TextField,
    DateInput,
    ListEMails,
    LocalizedText,
    PromoCodeScopes
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { shops, selectedShop } = useShopAdmin(props, context);
    const { createPromoCode, updatePromoCode, updatePromoCodeFormForUpdate, promoCodeForm }  = usePromoCodeAdmin(props, context);

    const { promoCode } = toRefs(props);

    const availableLanguageCodes = ['fr','en'];

    if(promoCode.value) {
      updatePromoCodeFormForUpdate(promoCode.value);
    }

    const buttonLabel = computed(() => {
      if(promoCode.value) { return app.$t('shop.update.button') }
      else { return "Create"; }
    });

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=promo-code-form] button.ladda-button' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      if(promoCode.value) {
        updatePromoCode().then((result) => {
          if(result.updated) {  
            context.emit('promoCode-updated', result.promoCode);
          }
          laddaSubmit!.stop();
        })
      }
      else {
        createPromoCode().then((result) => {
          if(result.created) {  
            context.emit('promoCode-created', result.promoCode);       
          }
          laddaSubmit!.stop();
        })
      }
    }

    return {
      promoCodeForm,
      onFormSubmit,
      availableLanguageCodes,
      buttonLabel
    }
  }
})
</script>