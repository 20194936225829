import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";

interface PromoCodeAdminInput {
}

export function usePromoCodeAdmin(props:PromoCodeAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);
  var promoCodes:Ref<any[]> = ref([]);

  const isCreateAllowed:Ref<boolean> = ref(false);
  const isUpdateAllowed:Ref<boolean> = ref(false);
  const isRemoveAllowed:Ref<boolean> = ref(false);
  const isMultiSelectAllowed:Ref<boolean> = ref(false);
  const showNumberOfRows:Ref<boolean> = ref(false);

  const updateList = async () => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/promo-codes');
      promoCodes.value = response.promoCodes ? response.promoCodes : [];
      if(response.options) {
        isCreateAllowed.value = response.options.isCreateAllowed;
        isUpdateAllowed.value = response.options.isUpdateAllowed;
        isRemoveAllowed.value = response.options.isRemoveAllowed;
        isMultiSelectAllowed.value = response.options.isMultiSelectAllowed;
        showNumberOfRows.value = response.options.showNumberOfRows;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateList();
    },
    { deep: false }
  )

  const promoCodeForm:any = reactive({
    code: '',
    note: undefined,
    emails: [],
    startDate: '',
    endDate: '',
    promos: [],
    isActivated: false
  });

  const updatePromoCodeFormForUpdate = (promoCode:any) => {
    promoCodeForm._id = promoCode ? promoCode._id : undefined;
    promoCodeForm.code = promoCode ? promoCode.code : '';
    promoCodeForm.note = promoCode ? promoCode.note : undefined;
    promoCodeForm.emails = (promoCode && promoCode.emails) ? promoCode.emails : [];
    promoCodeForm.startDate = (promoCode && promoCode.startDate) ? promoCode.startDateInput : '';
    promoCodeForm.endDate = (promoCode && promoCode.endDate) ? promoCode.endDateInput : '';
    promoCodeForm.promos = promoCode ? promoCode.promos : [];
    promoCodeForm.isActivated = promoCode ? promoCode.isActivated : false
  }

  const createPromoCode = async () => {
    var input = {
      ...promoCodeForm
    }

    var result = {
      created:false,
      promoCode: undefined
    }

    var path = '/shop/'+selectedShop.value.shop._id+'/promo-codes/create';
    
    try {
      var response = await callShopAdmin(path, input);
      if(response.created) {  
        result.created = true;
        result.promoCode = response.promoCode     
      }
    }
    catch(error) {
      console.log(error);
    }
    return result;
  }

  const updatePromoCode = async () => {
    var input = {
      ...promoCodeForm
    }

    var result = {
      updated:false,
      promoCode: undefined
    }

    var path = '/shop/'+selectedShop.value.shop._id+'/promo-code/'+promoCodeForm._id+'/update';
    
    try {
      var response = await callShopAdmin(path, input);
      if(response.updated) {  
        result.updated = true;
        result.promoCode = response.promoCode     
      }
    }
    catch(error) {
      console.log(error);
    }
    return result;
  }

  const removePromoCode = async (promoCodeID:string) => {

    isListLoading.value = true;

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/promo-code/'+promoCodeID+'/remove');
      isListLoading.value = false;
      return response;
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  return {
    promoCodes,
    removePromoCode,
    promoCodeForm,
    updatePromoCodeFormForUpdate,
    createPromoCode,
    updatePromoCode,
    updateList,
    isListLoading,
    isCreateAllowed,
    isUpdateAllowed,
    isRemoveAllowed,
    isMultiSelectAllowed,
    showNumberOfRows
  }
  
}